import React from "react";
import { animateScroll as scroll } from "react-scroll";
import { FaFacebook, FaInstagram, FaYoutube, FaLinkedin } from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterForHomeElements";

const FooterForHome = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer  >
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Pomoc: </FooterLinkTitle>
              <FooterLink to="/privacy">Polityka Prywatności</FooterLink>
              <FooterLink to="/regulamin">Regulamin </FooterLink>
              <FooterLink to="/dostawa">Dostawa</FooterLink>
              <FooterLink to="/platnosci">Płatności</FooterLink>
              <FooterLink to="/galeria/800x600">Galeria</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Kontakt: </FooterLinkTitle>
              <FooterLink>get.lofta@gmail.com</FooterLink>
              <FooterLink>+48 789324329</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Sprzedaż: </FooterLinkTitle>
              <FooterLink
                to={{ pathname: `https://getloft.olx.pl/` }}
                target="_blank"
              >
                GetLoft OLX
              </FooterLink>
              <FooterLink
                to={{ pathname: `https://allegrolokalnie.pl/uzytkownik/funti` }}
                target="_blank"
              >
                GetLoft Allegro
              </FooterLink>
              <FooterLink to="/">
                SKLEP
              </FooterLink>
              <FooterLink to="/klatki-kennelowe-cennik" exact="True">
                CENNIK
              </FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>GetLoft</FooterLinkTitle>
              <FooterLink>Nip: 8762148828</FooterLink>
              <FooterLink>Regon: 340706952</FooterLink>
              <FooterLinkTitle>Adres: </FooterLinkTitle>
              <FooterLink>86-300 Grudziądz</FooterLink>
              <FooterLink>ul. Droga Bociania 5a</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              funta $
            </SocialLogo>
            <WebsiteRights>- funtoshi - © 2024 All rights reserved.</WebsiteRights>
            <SocialIcons>
              <SocialIconLink href="https://www.facebook.com/people/GetLoftpl/100068615335437/" target="_blank" aria-label="Facebook">
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href="https://www.instagram.com/getloft.pl/" target="_blank" aria-label="Instagram">
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink href="/" aria-label="Youtube">
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink href="/" aria-label="Linkedin">
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default FooterForHome;
